import $ from 'jquery'

import 'slick-carousel'
import WOW from 'wow.js'
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js"
const simpleParallax = require('simple-parallax-js')

import './style.scss'
//import '../node_modules/wow.js/css/libs/animate.css'
import '../node_modules/wow.js/css/libs/animate.css'


// WOW INIT
new WOW().init()

// ADD CLASS NAV IF SCROLL
let scrollpos = window.scrollY
const header = document.querySelector("nav")
const offcanvas = document.getElementsByClassName('navbar-toggler')[0].getAttribute('offcanvas-menu')
const header_height = header.offsetHeight
const add_class_on_scroll = () => header.classList.add("scrolled")
const remove_class_on_scroll = () => header.classList.remove("scrolled")
window.addEventListener('scroll', function() {
  scrollpos = window.scrollY
  if (scrollpos >= header_height) {
    add_class_on_scroll()
    document.getElementById(offcanvas).classList.add("scrolled")
  }
  else {
    remove_class_on_scroll()
    document.getElementById(offcanvas).classList.remove("scrolled")
  }
  // console.log(scrollpos)
})
window.addEventListener("load", function() {
  if (scrollpos >= header_height) {
    add_class_on_scroll()
    document.getElementById(offcanvas).classList.add("scrolled")
  }
  else {
    remove_class_on_scroll()
    document.getElementById(offcanvas).classList.remove("scrolled")
  }
})

// PARALLAX INIT
var parallaxObj = document.getElementsByClassName('parallax-img')
new simpleParallax(parallaxObj)

// OFFCANVAS MENU
function offcanvasMuscle() {
  var triggers = document.getElementsByClassName('navbar-toggler')
  for (var i = 0; i < triggers.length; i++) {
    triggers[i].addEventListener("click", function() {
      var targetMenu = this.getAttribute('offcanvas-menu')
      console.log(targetMenu)
      toggleOffcanvasMuscleMenu(targetMenu)
    })
  }
}
function toggleOffcanvasMuscleMenu(menuId) {
  var myMenu = document.getElementById(menuId)
  var triggers = document.getElementsByClassName('navbar-toggler')[0]
  var triggersMob = document.getElementsByClassName('navbar-toggler')[1]
  triggers.classList.toggle('expa')
  triggersMob.classList.toggle('expa')

  myMenu.classList.toggle('open')
  // var siteWrap = document.getElementsByClassName('site-wrap')[0]
  // siteWrap.classList.toggle('open')
  // if (myMenu.classList.contains('left'))
  // siteWrap.classList.toggle('left')
  // if (myMenu.classList.contains('right'))
  // siteWrap.classList.toggle('right')
}
offcanvasMuscle()

// TABS SCRIPT
window.addEventListener("load", function() {
	// store tabs variable
	var myTabs = document.querySelectorAll("ul.nav-tabs > li")
  function myTabClicks(tabClickEvent) {
		for (var i = 0; i < myTabs.length; i++) {
			myTabs[i].classList.remove("active")
		}
		var clickedTab = tabClickEvent.currentTarget
		clickedTab.classList.add("active")
		tabClickEvent.preventDefault()
		var myContentPanes = document.querySelectorAll(".tab-pane")
    console.log(myContentPanes);
		for (var i = 0; i < myContentPanes.length; i++) {
			myContentPanes[i].classList.remove("active")
		}
		var anchorReference = tabClickEvent.target
		var activePaneId = anchorReference.getAttribute("href")
		var activePane = document.querySelector(activePaneId)
		activePane.classList.add("active")
	}
	for (var i = 0; i < myTabs.length; i++) {
		myTabs[i].addEventListener("click", myTabClicks)
	}
})


// JQUERY DEP
$(document).ready( function(){

  var ap = true

  // LOAD SLIDER FHEIGHT
  $('.slick-top').slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 500,
    fade: true,
    dots: true,
    arrows: false
  })
  // Run when slides change
  $('.slick-top').on('afterChange', function(event, slick, currentSlide) {
    // Hide/show captions
    $('.slick-top').find("h3").removeClass("animated fadeInUp")
    $(slick.$slides.get(currentSlide)).find("h3").addClass("animated fadeInUp")
  })

  // LOAD PARTIAL SLIDER TEXT
  $('.slider-text').slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    infinite: true,
    arrows: true,
    prevArrow: $('body').find('.slider-text-wrapper .slick-left'),
    nextArrow: $('body').find('.slider-text-wrapper .slick-right'),
  })

  // LOAD PARTIAL SLIDER IMAGES
  $('.slider-img').slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    focusOnSelect: true,
    infinite: true,
    arrows: true,
    variableWidth: true,
    // onInit: function () {
    //   jQuery(window).resize()
    //   console.log('slickcaroseal locded')
    // },
    centerMode: false,
    prevArrow: $('body').find('.partial-gallery .slick-left'),
    nextArrow: $('body').find('.partial-gallery .slick-right')
  })

  // SCROLL NEXT SECTION
  var $scrollSection = $('section')
  var $scrollTrigger = $('.scroll-down')
  var nextSection = 1
  $scrollTrigger.on('click', function() {
    $('html, body').animate({
      scrollTop: $($scrollSection[nextSection]).offset().top+100
    }, 1000)
  })

  // HEIGHT MASONRY GRID FROM ATTRIBUTE
  var heightAttr = $(".index-masonry-gallery #wrapper").data("height")  
  $(".index-masonry-gallery #wrapper").css("max-height", heightAttr)

  // INIT GMAPS
  function initMap() {
    var positionM = { lat: 45.506012614677175, lng: 12.030356084178015 };
    var icon = '/wp-content/themes/lagineria/assets/imgs/marker-gineria.png';
    var map = new google.maps.Map(document.getElementById("gmap"), {
      styles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#dee7f5"},{"lightness":17}]}],
      center: positionM,
      zoom: 9,
      scrollwheel: false,
      disableDefaultUI: true
    });
    var marker = new google.maps.Marker({
      map: map,
      position: positionM,
      title: "La Gineria | Santa Maria di Sala",
      icon: icon
    })
    document.getElementById("gmap").addEventListener('click', function (event) {
      window.open("https://g.page/lagineria?share",'_blank');
    }, false);
  }
  if (document.getElementById("gmap") != null) {
    initMap()
  }

})
